.container_create_proposal {
    display: flex !important; 
    flex-direction: column !important;
    justify-content: center !important;   
    align-items: center !important; 
    min-height: 50vh !important;
    margin: 32px 0px 32px 0px  !important;
}

.create_proposal_heading {
    font-size: 24px !important; 
    font-weight: 600 !important;
    line-height: 32.68px !important;
    text-align: left !important;
    
}

.create_proposal_descp {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 20.43px !important;
    text-align: left !important;
}

.stepper_style {
    width: 153px !important;
    height: 36px !important;
    padding: 6px 8px 6px 8px !important;
    gap: 8px !important;
    border-radius: 24px 0px 0px 0px !important;
    border: 1px 0px 0px 0px !important;
    opacity: 0px !important;
}

.label_index_style {
    width: 34px !important;
    height: 34px !important;
    font-size: 13px !important;
    border: 1px solid rgba(0,0,0,0.1) !important; 
    border-radius: 100% !important
}

.create_compo_width {
    width: 800px !important;
}

.padding_24 {
    padding: 24px !important;
}

.btn_width {
    min-width: 120px !important;
}

.next_btn_style {
    background: #00B13F !important;
    color: #fff !important;
}

.component_container {
    display: flex !important;
    flex-direction: column;
    gap: 24px !important;
}

.component_heading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 24.51px !important;
}

.component_description {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;

}

.error_message_text {
    font-size: 13px !important;
    color: red !important;
    
}

.item_name_width {
    width: 28% !important;
}

.quantity_width {
    width: 15% !important;
}

.price_width {
    width: 13% !important;
}

.periodicity_width {
    width: 21.5% !important;
}

.action_items {
    width: 13% !important;
}

.add_item_tabel_settings_icon {
    position: relative !important;
    top: 5px !important;
    font-size: 20px !important;
    left: 4px !important;
    cursor: pointer !important;
}

.add_item_table_heading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.07px !important;
}

.add_item_table_descp {
    font-size: 13px !important;
    font-weight: 400 !important;
}

.add_item_icon_btn {
    padding: 4px !important;
    min-width: 10px !important;
}

//edit Proposal Style
.edit_proposal_container {
    height: calc(100vh - 100px) !important;
    padding: 16px 24px !important;
    overflow: auto;
}

.edit_prop_icon_btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
}

.prop_title {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 32.68px !important;

}

.edit_header_icon_btn {
    padding: 6px !important;
}

.table_cell_style {
    padding: 12px 4px !important;// Consistent padding for both modes
    white-space: nowrap !important; // Prevent text overflow
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.table_cell_style1 {
    padding: 16px 6px !important;// Consistent padding for both modes
    white-space: nowrap !important; // Prevent text overflow
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

//edit and drawer style

.drawer_content_container {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
    padding: 16px;
    overflow-y: auto !important;
    max-height: calc(100vh - 154px) !important; // Full height minus header and footer heights
    flex-grow: 1 !important; 
}

.drawer_element_padding {
    padding: 0px 16px !important;
}

.drawer_footer {
    position: absolute !important;
    width: 100% !important;
    bottom:0 !important; 
    padding: 16px !important;
    border-top: 1px solid #dddddd !important;
    background: #ffffff !important;
}

.drawer_btn_width {
    width: 100px !important
}

.drawer_label_style {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.drawer_textfield_margintop {
    margin-top: 3px !important;
}

.drawer_list_items {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
}

.drawer_list_items_heading {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
}

.drawer_list_items_descp {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
}

.drawer_list_save_btn {
    background: #0C66E4 !important;
    color: #ffffff !important
}

.drawer_edit_item_container {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
    cursor: pointer !important;
}

.drawer_descp_cursor {
    cursor: pointer !important;
}

.drawer_link_style {
    font-size: 14px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    margin-bottom: 4px !important;
}

//mdx editor styles
.MarkDownToolBar {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    z-index: 1000 !important;
}

.MarkDownEditors {
    margin-top: 3px !important;
}

.mdxeditor {
    //border: 1px solid #ccc !important;
    border-radius: 8px;
    font-family: 'Open Sans' !important;
}

.mdxeditor-popup-container {
    z-index: 2000 !important;
}

.mdxeditor-toolbar {
    border: 1px solid #ccc !important;
    border-bottom: none !important;
    position: relative !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.drawer_content_container .mdxeditor-root-contenteditable {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-top: none !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    cursor: text !important;
}

.MarkDownEditors1 {
    height:100% !important;
}

.drawer_content_container .MuiOutlinedInput-input {
    padding: 10px 14px !important;
}

//edit section styles

.edit_drawer_add_btn {
    border: 1px solid #ccc !important;
    color: #555555 !important
}

.itemList {
    position: relative !important;
    padding: 8px 16px !important;
}

.itemList:hover {
    background-color: #f9f9f9 !important;
}

.edit_drawer_delete_icon {
    visibility: hidden !important;
    position: absolute !important;
    top: 6px !important;
    right: 0 !important;
}

.itemList:hover .edit_drawer_delete_icon {
    visibility: visible !important;
}

.edit_drawer_textfield {
    background-color: #fff !important;
}

.edit_section_container {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important
}

.edit_section_container ._contentEditable_uazmk_379 {
    background-color: #fff !important;
    padding: 0 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px !important;
}

.edit_section_container ._contentEditable_uazmk_379 p {
    margin: 12px 0px !important;
}

.edit_section_container .why_us_bulletpoints {
    gap:8px !important;
}

.edit_section_heading {
    font-size: 18px !important;
    font-weight: 700 !important;
}

.edit_section_descp {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.edit_section_item_container {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important
}


//footer
.footer_btn {
    width: 120px !important;
}

.footer_btn_cancel {
    border: 1px solid #555555 !important;
    color: #555555 !important;
}

.footer_btn_preview {
    background-color: #555555 !important;
    color: #fff !important;
}

.footer_btn_finish {
    background-color: #00B13F !important;
    color: #fff !important;
}

.preview_list ._contentEditable_uazmk_379 {
    background-color: var(--lighter-color) !important;
    padding: 0 !important;
}

.preview_bottom_arc {
    width: 165px !important;
    height: 165px !important;
    border-radius: 0 100% 0 0 !important;
    position: absolute !important;
    left: 0px !important;
    bottom: 0px !important;

     // Adjust for medium screens (tablets)
    @media (max-width: 999px) {
        width: 150px !important;
        height: 150px !important;
        left: 0px !important;
        bottom: 0px !important;
    }

    // Adjust for medium screens (tablets)
    @media (max-width: 768px) {
        width: 110px !important;
        height: 110px !important;
        left: 0px !important;
        bottom: 0px !important;
    }
    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        width: 80px !important;
        height: 80px !important;
        left: 0px !important;
        bottom: 0px !important;
    }
}

.preview_top_arc {
    position: absolute;
    top: -145px;
    right: -145px;
    width: 175px;
    height: 175px;
    background-color: var(--lighter-color) !important;
    border: 65px solid var(--original-brand-color) !important;
    border-radius: 100%;

    // Adjust for medium screens (tablets)
    @media (max-width: 999px) {
        top: -120px;
        right: -120px;
        width: 150px;
        height: 150px;
        border-width: 50px;
    }

    // Adjust for medium screens (tablets)
    @media (max-width: 768px) {
        top: -110px;
        right: -110px;
        width: 130px;
        height: 130px;
        border-width: 50px;
    }

    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        top: -60px;
        right: -60px;
        width: 70px;
        height: 70px;
        border-width: 25px !important;
    }
}

.preview_description_style {
    font-size: 18px !important;
    line-height: 33px !important;
    font-weight: 400 !important;

    @media (max-width: 768px) {
        font-size: 15px !important;
        line-height: 25px !important;
        font-weight: 400 !important;
    }

    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 400 !important;
    }
}

.hide_page_style {
    opacity: 50% !important;
}

.drawer_loading_margin {
    color: #fff !important;
    margin-right: 8px !important;
}

.preview_description_style1 {
    font-size: 16px !important;
    line-height: 32px !important;
    font-weight: 400 !important;

    @media (max-width: 768px) {
        font-size: 14px !important;
        line-height: 25px !important;
        font-weight: 400 !important;
    }

    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        font-size: 13px !important;
        line-height: 20px !important;
        font-weight: 400 !important;
    }
}

.proposal_preview {
    background: white;
    border-radius: 5px;
    padding:16px;
    gap: 16px
}

.preview_bottom_arc1 {
    width: 160px !important;
    height: 160px !important;
    border-radius: 0 100% 0 0 !important;
    position: absolute !important;
    left: 0px !important;
    bottom: 0px !important;

     // Adjust for medium screens (tablets)
    @media (max-width: 999px) {
        width: 150px !important;
        height: 150px !important;
        left: 0px !important;
        bottom: 0px !important;
    }

    // Adjust for medium screens (tablets)
    @media (max-width: 768px) {
        width: 110px !important;
        height: 110px !important;
        left: 0px !important;
        bottom: 0px !important;
    }
    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        width: 80px !important;
        height: 80px !important;
        left: 0px !important;
        bottom: 0px !important;
    }

}

.preview_action_btns {
    color: #333 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.proposals_footer_buttons {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    position: fixed !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    padding: 16px 32px !important;
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px #00000026 !important;

    // Adjust for small screens (mobile)
    @media (max-width: 480px) {
        padding: 16px !important;
        justify-content: center !important;
    }
}

.approveButton {
    background-color:green !important;
    color: #fff !important;
    &:hover  {
        background-color:green !important;
        opacity:0.8 !important;
    }
}

.rejectButton {
    background-color:#8b0000 !important;
    color: #fff !important;
    &:hover  {
        background-color:#8b0000 !important;
        opacity:0.8 !important;
    }
}

.preview_table_head .MuiTableCell-head {
    background:var(--original-brand-color) !important;
    color: #fff !important;
}

.drawer_content_container ._contentEditable_uazmk_379 {
    line-height: 24px !important;
}

.profilePicBackdrop {
    visibility: hidden;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    color: white !important;
}

.settings_icon_color {
    filter: invert(32%) sepia(60%) saturate(4968%) hue-rotate(199deg) brightness(102%) contrast(103%) !important;
}

.edit_list_width {
    width: 98% !important;
}

.team_image_container {
    width: 100% !important;
    height: 260px !important;
    overflow: hidden !important;
    display: flex !important;
    justify-items: center !important;
    align-items: center !important;
    background: #f9f9f9 !important;
}

.profile_pic_container {
    width: 110px !important;
    height: 110px !important;
    border-radius: 100% !important;
    overflow: hidden !important;
    display: flex !important;
    justify-items: center !important;
    align-items: center !important;
    background: #f9f9f9 !important;
}

.image_style {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
}