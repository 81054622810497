.menu {
    background: #ffffff;
    position: absolute;
    top: 32px;
    right:12px !important;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-20px);
    //transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
    border: 1px solid #eef0f2;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .menu1_margin {
    right: 6.4%;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu li a {
    text-decoration: none;
    color: #2B2B2B;
    padding: 7px 7px;
    display: block;
    font-size: 15px;
  }
  
  .menu ul li a:hover {
    background: #dde0e4 !important;
    cursor: pointer;
  }

  //preview ui dropdown
  .menu1 {
    background: #ffffff;
    position: absolute;
    top: 40px;
    width: 190px;
    padding: 8px 0px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-20px);
    //transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
    border: 1px solid #eef0f2;
    border-radius: 6px;
    box-sizing: border-box
  }

  .menu1_margin {
    right: 3.5%;
  }

  .menu1.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  
  .menu1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu1 li a {
    display: flex !important; 
    align-items: center !important;
    text-decoration: none;
    color: #333333;
    padding: 7px 10px;
    display: block;
    font-size: 15px;
  }
  
  .menu1 ul li a:hover {
    background: #dde0e4 !important;
  }

  //dropdown for tax preset styles
  .dropdown{
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 16px;
  }

  .dd-button {
    width:auto !important;
    border-radius: 6px !important;
    padding: 1px 8px !important;
    background: #e3e8f5 !important;
    font-size: 12px !important;
    color: #3c51be !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
  }

  .dd-button1 {
    width:auto !important;
    border-radius: 6px !important;
    padding: 1px 8px !important;
    background: #edeef0 !important;
    font-size: 12px !important;
    color: #4a5568 !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
  }

  .dd-button2 {
    width:100% !important;
    border-radius: 6px !important;
    padding: 1px 8px !important;
    background: rgb(243, 244, 246 / 0.1) !important;
    font-size: 12px !important;
    color: #0da72f !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
  }

  // .dd-button:after {
  //   content: '';
  //   position: relative;
  //   top: 9px;
  //   margin-left: 8px;
  //   width: 0; 
  //   height: 0; 
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-top: 5px solid #3c51be;
  // }

  // .dd-button1:after {
  //   content: '';
  //   position: relative;
  //   top: 9px;
  //   margin-left: 8px;
  //   width: 0; 
  //   height: 0; 
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-top: 5px solid #4a5568;
  // }

  
  
  .dd-button:hover {
    background-color: rgba(60, 82, 190, 0.108) !important;
  }

  .dd-button1:hover {
    background-color: rgba(224, 224, 224, 0.382) !important;
  }

  .dd-button2:hover {
    background-color: rgba(224, 224, 224, 0.382) !important;
  }
  
  .dd-menu {
    display: none;
    width: 244px;
    position: absolute;
    top: 100%;
    border: 1px solid #eef0f2;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(82, 91, 100, 0.1);
    list-style-type: none;
    overflow: auto;
    z-index: 101 !important;
  }
  
  .dd-menu.active {
    display: block;
  } 
  
  .dd-menu li{
    padding: 16px;
    cursor: pointer;
    white-space: nowrap; 
  }
  
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }

  .dd-menu li.last_item:hover {
    background-color: transparent !important;
  }
  
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }

  .d_flex {
    display: flex; 
    justify-content: space-between;
  }

  .d_flex.center {
    align-items: center;
  }

  .clear_icon {
    font-size: 18px !important; 
    padding-left: 8px; 
    cursor: pointer !important;
  }

  .arrow_down_icon {
    font-size: 20px !important; 
    padding-left: 4px !important; 
    cursor: pointer !important;
  }

.modal__main {
    margin-top: 16px;
    padding: 0px 16px 8px 16px;
    height: calc(100vh - 130px);
    overflow: auto !important;
}

.modal__main1 {
  margin-top: 16px;
  padding: 16px;
  height: calc(100vh - 130px);
  overflow: auto !important;
}

.modal__main::-webkit-scrollbar {
  display: none;
}

.modal__main1::-webkit-scrollbar {
  display: none;
}

  /**Modal Footer**/
.modal__footer {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0;
  right:0;
  padding: 12px 0px 12px 25px;
  z-index:15;
  background: #fff;
  border-top: 1px solid #ccc;
}

.modal__button {
  min-width: 130px !important;
  color: white;
  margin-right: 3% !important;
}

.border_bottom {
  margin: 0px 32px 0px 0px;
  border-bottom: 1px solid rgba(0,0,0,.1); 
}

.labels_styles {
  cursor: pointer !important;
  display: flex;
  align-items: center;
}

.visibility_icon {
  margin-right: 3px;
  color:lighten(#4c51bf, 25%);
  font-size: 18px !important;
  position: relative;
  top: 1px;
}

.show_hideLabels {
  color:#4c51bf;
  font-size: 13px !important;
  font-weight: 500;
}

.bill_invoice {
  margin: 8px 0px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.dashed_border {
  margin: 16px 0px;
  border-bottom: 2px dotted rgba(0,0,0,.1); 
}

.item_marginTop {
  margin-top:12px !important;
}

.payment_stubContainer {
  padding: 24px; 
  background: #f6f8fb;
  border-radius: 5px;
  height: 290px;
}

.item_marginTop1 {
  margin-top:9px !important;
}

.clientOwner_address {
  display: flex;
  flex-direction: column;
  font-size: 13px !important;
  color:#4a5568 !important;
  font-weight: 400 !important;
}

.show_labels1 {
  cursor: pointer !important;
  display: flex;
  align-items: center;
}

.save_invoice {
  background: white;
  border-radius: 5px;
  padding:16px;
}

.modalBorderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px 16px 0px 24px
}

.company_heading {
  font-size: 15px !important; 
  font-weight: 600 !important;
  color: #4a5568 !important;
}

.bill_label {
  font-size: 13px !important;
  font-weight: 600 !important;
  color:#535dc5;
  line-height: 24px !important;
}

.table_container {
  background:#f6f8fb; 
  padding: 8px; 
  border-radius: 5px;
}

.payment_terms {
  font-size: 13px !important; 
  margin-top: 4px !important;
  line-height: 20px !important;
  font-weight: 500;
}

.client_notes {
  font-size: 13px !important; 
  margin-top: 4px !important; 
  line-height: 20px !important;
  font-weight: 500;
}

.font_styles {
  font-weight: 400 !important;
  color: #4a5568 !important;
  font-size: 13px !important;
  margin-bottom: 8px !important;
}

.font_styles1 {
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #4a5568 !important; 
}

.font_styles2 {
  color: #4a5568 !important;
  font-size: 13px !important;
}

.more_actionsIcons {
  margin-right: 6px; 
  color: #b5bbc2,
}

.text_field {
  margin-top: -2px !important;
}

.tax_preset {
  font-size: 15px !important;
}

.company__menu{
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 64px;
  width: 100%;
  //box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  border:1px solid #DBDBDB !important;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  overflow: auto;
  height: auto !important;
  max-height: 250px !important;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1300 !important;
}

.company__menu::-webkit-scrollbar {
  display: none;
}

.company__menu {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.company__menu.active {
opacity: 1;
visibility: visible;
transform: translateY(0);
}

.company__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.company__menu li span {
  text-decoration: none;
  color: #333333;
  padding: 10px 16px 10px 16px;
  display: block;
}

.company__menu li:hover span {
  background:'#ccc !important';
  cursor:'pointer'
}

/***Status Filters list circle backgrounds**/
.draft__status {          
  background: #828c98;
}

.sent__status {
  background: #4c51bf;
}

.downloaded__status {
  background: #4a5568;
}

.viewed__status {
  background: rgb(204, 162, 14);
}

.partial__status {
  background: #006644;
}

.paid__status {
  background: green;
}

.cancelled_status {          
  background: #ff0000;
}

/**Status filter list backgrounds **/
.draft__background {
  background-color: #f9f9f9;
}

.sent__background {
  background-color: rgba(76, 81, 191, 0.1);
}

.downloaded__background {
  background-color: rgba(74, 85, 104, 0.1);
}

.viewed__background {
  background-color: rgba(204, 162, 14, 0.1);
}

.partial__background {
  background-color: rgba(0, 102, 68, 0.1);
}

.paid__background {
  background: rgba(0, 128, 0, 0.1);
}

.cancelled__background {
  background: rgba(244, 67, 54, 0.1);
}

.approved__background {
  background: rgba(0, 100, 0, 0.1);
}

.rejected__background {
  background: rgba(139, 0, 0, 0.1);
}


.status__filterList {
  color: rgba(0, 0, 0, 0.719);
  width: auto;
  margin-bottom: 10px !important;
  margin-right: 5px !important;
  padding: 7px 6px 7px 6px;
  border-radius: 4px ;
  cursor: pointer;
}

.status__filterList.active {
border: 1px solid #314577;
}

.circle  {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block
}

.flaticon-filter.active {
color: #0076ff !important
}  

.filter__dot {
    width: 5.5px;
    height: 5.5px;
    background: #0076ff;
    border-radius: 50%;
    position: absolute;
    top: 2.7px;
    left: 21.5px;
}

.client_email {
  padding: 4px 8px !important;
}

.return__email {
  padding: 10px 14px 10px 14px !important;
  border-radius: 4px !important;
  display: flex;
  background-color: #f9f9f9 !important;
  border: 1px solid rgba(0,0,0, 0.1) !important;
}

.modalBorderBottom1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px 24px 0px 24px
}

.dottedborderBottom {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  margin: 0px 24px 0px 24px
}

.download_invoice {
  background: white;
  border-radius: 5px;
  padding:16px;
  width: 100%;
}

/**total subtotal item styles **/
.list_item_client_style {
  width: 210px !important; 
  font-size: 13px !important;
  color: #4a5568 !important;
  line-height: 23px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

.list_item_styles {
  font-size: 13px !important;
  color: #4a5568 !important;
  line-height: 23px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  
}

.list_item_styles1 {
  font-size: 13px !important;
  color: #4c51bf !important;
  line-height: 23px !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  cursor: pointer !important;
  
}

.preview_list_style {
  font-size: 13px !important;
  color: #4a5568 !important;
  line-height: 23px !important;
}

.preview_list_name_style {
  font-size: 15px !important;
  color: #4a5568 !important;
  line-height: 23px !important;
  font-weight: 600 !important;
}

.preview_invoice_style {
  font-size: 32px !important; 
  color: #4a5568 !important;
}

.preview_invoice_desp_style {
  margin-top: 0px !important; 
  font-size: 13px !important; 
  color: #4a5568 !important
}

.preview_invoice_text_style {
  font-size: 13px !important; 
  color: #4a5568 !important;
  margin-bottom: 0px !important;
}

.mobile_list_name_style {
  font-size: 15px !important;
  color: #4a5568 !important;
  line-height: 23px !important;
  font-weight: 600 !important;
  margin: 8px 0px 8px 8px !important;
}

.mobile_list_style {
  font-size: 13px !important;
  color: #4a5568 !important;
  margin: 8px 0px 8px 8px !important;
}

.mobile_flex_display {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.bill_invoice1 {
  margin: 8px 8px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.dottedborderBottom1 {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  margin: 16px 24px 8px 24px
}

.proforma__invoice { 
  background: rgb(204, 162, 14);         
  
}

.tax__invoice {
  background: #4c51bf;
}

.none__invoice {
  background: #4a5568;
}


.proforma__background {
  background-color: rgba(204, 162, 14, 0.1);
}

.tax__background {
  background-color: rgba(76, 81, 191, 0.1);
}

.none__background {
  background-color: #4a55681e;
}

.loading__style {
  margin-right: 8px !important; 
  color: white !important;
}

.button_purple {
  background: #4c51bf !important; 
  color: #fff !important;
}

.tableheading_style {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #222 !important
}

.tablebody_style {
  font-size: 13px !important;
  color: #333 !important
}

.preview_invoice_one {
  background: white !important;
  padding: 0px 0px 32px 0px !important;
  border-radius: 5px !important;
  flex-basis: 58% !important;
  max-width: 58% !important;
}

.preview_invoice_two {
  background: white !important;
  padding: 0px 0px 32px 0px !important;
  border-radius: 5px !important;
  
}

.esign_style {
  background: white;
  border-radius: 5px;
  padding:16px;
  margin-top: 16px !important;
}

.border_bottom_one {
  margin-top: 8px;
  border-bottom: 1px solid rgba(0,0,0,.1); 
}

.eSign_style {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 192px !important;
    height: 72px !important;
    padding: 16px !important;
    border-radius: 5px !important;
    border: 1px solid rgba(0,0,0,0.1) !important;
    box-sizing: border-box !important;
}

.border_bottom_two {
  border-bottom: 1px solid rgba(0,0,0,.1); 
  margin: 0px 24px 0px 24px
}


.MuiPopover-paper {
  width: 300px;
}

.date_popover_style {
  margin-top:8px !important;
  width: 273px !important;
}

.date_popover_style_one {
  position: absolute !important;
  top: -50px !important;
  left: -60px !important;
  width: 273px !important;
}

.filter_date_popover {
  width: 760px !important;
  overflow: visible !important;
  position: absolute !important;
  right: 60px !important;
  box-shadow: none !important;
}

.link_style {
    color: #0000EE !important;
    text-align: center !important;
    padding:0 !important;
    display: flex !important;
    justify-content: center !important;
}

.link_style1 {
  color: #0000EE !important;
  margin-left: 2px !important;
  padding:0 !important;
  cursor: pointer !important;
}

.link_style:hover {
  text-decoration: underline !important;
}

.link_style1:hover {
  text-decoration: underline !important;
}


.upgradeMessageStyle {
  padding: 8px 16px !important;
  background-color: rgb(253, 236, 234) !important;
  color:  rgb(97, 26, 21) !important;
  margin-bottom: 16px !important;
  border-radius: 4px !important
}

.tabStyle{
  .MuiTabs-root {
      min-height: 24px !important;
  }
  .MuiTab-root {
      padding: 7px 8px !important
  }   
}

.client_owner_name {
  color: #4a5568 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.checkmark {
  width: 30px !important;
  height: 30px !important;
  background-color: green !important;
  border-radius: 30px 0 0 0 !important;
  color: white !important;
  font-size: 20px !important;
  text-align: center !important;
  line-height: 30px !important;
  position: absolute !important;
  right: -6px !important;
  bottom: -10px !important;
}

.invoice_text_color {
  color: #4a5568 !important;
}

.tableheading_style1 {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #4a5568 !important
}

.purple_text {
    color:#8049df !important;
}

.status_style {
  width: 4px !important;
  height: 20px !important;
  margin-right: 4px !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.text_ellipsis {
  width: auto;
  max-width: 210px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text_ellipsis1 {
  width: auto;
  max-width: 200px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media (max-width: 1950px) {
  .text_ellipsis{
      max-width: 180px !important;
  }

  .text_ellipsis1{
    max-width: 180px !important;
  }
}

@media (max-width: 1600px) {
  .text_ellipsis{
    max-width: 150px !important;
  }

  .text_ellipsis1{
    max-width: 160px !important;
  }
}

@media (max-width: 1500px) {
  .text_ellipsis{
    max-width: 110px !important;
  }

  .text_ellipsis1{
    max-width: 120px !important;
  }
}

@media (max-width: 1300px) {
  .text_ellipsis{
    max-width: 110px !important;
  }

  .text_ellipsis1{
    max-width: 100px !important;
  }
}

.icon_position_top {
  position: relative !important;
  top: -1px !important;
}

.validitySyle {
  font-size:14px !important;
  line-height:16px !important;
  color: #212121 !important;
  background: #ffffbf !important;
  padding:16px !important;
  border-radius:6px !important;
}

//estimate status color 
.est_open__status {
  background: #007bfb;
}

.est_approved__status {          
  background: darkgreen;
}

.est_partial__status {
  background: orange;
}

.est_cancelled__status {          
  background: #f44336;
}

.est_rejected__status {          
  background: #8b0000;
}

.est_closed__status {          
  background: #828c98;
  background: rgb(130, 140, 152);
}


//estimate status background
.est_open__background {
  background-color: rgba(0, 123, 251, 0.1);
}

.est_approved__background {
  background: rgba(0, 100, 0, 0.1);
}

.est_partial__background {
  background-color: rgba(255, 165, 0, 0.1);
}

.est_cancelled__background {
  background: rgba(244, 67, 54, 0.1);
}

.est_rejected__background {
  background: rgba(139, 0, 0, 0.1);
}

.est_closed__background {
  background: rgba(130, 140, 152, 0.1);
}

.none_bill_type {
  background: #cccccc;
}

.partial_bill_type {
  background: #ff9800;
}

.invoiced_bill_type {
  background: #4caf50;
}

.none_bill_type_background {
  background-color: rgba(204, 204, 204, 0.1);
}

.partial_bill_type_background {
  background-color: rgba(255, 152, 0, 0.1);
}

.invoiced_bill_type_background {
  background-color: rgba(76, 175, 80, 0.1);;
}

.invoice_type_style{ 
  font-size: 10px !important;
  border-radius: 4px !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #ececec !important;
  padding: 3px !important;
  margin-right: 5px !important;
}

.issue_date_text_ellipsis {
  max-width: 70px !important;
  font-size: 12px !important;
  white-space:  nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important
}

@media (max-width: 1400px) {
  .issue_date_text_ellipsis{
    max-width: 55px !important;
  }
}


.table_header_icons {
  font-size: 19px !important;
  color: #555 !important;
}

.filter_acitve {
  color: #0076ff !important
}

.table_header_icon_column {
  width: 30px !important;
}

.invoice_no_style {
  font-size: 10px !important;
  border-radius: 4px !important;
  background: #f9f9f9 !important;
  border: 1px solid #ececec !important;
  padding: 3px !important;
  margin-right: 3px !important;
}

.status_style {
  width: auto;
  max-width: 80px !important;
}

.filter_icon {
  width: 21px !important;
  margin-left: -4px !important;
}

.filter_icon.active {
  color: #0076ff !important;
  filter: invert(33%) sepia(71%) saturate(4221%) hue-rotate(202deg) brightness(101%) contrast(107%) !important;
}